import { useState } from 'react'
import { Route, Routes } from 'react-router'
import Header from '../Header/Header'
import SideMenu from '../SideMenu/SideMenu'
import Content from '../Content/Content'
import Help from '../Help/Help'

import Helpdesk from '../../views/Helpdesk/Helpdesk'
import Dashboard from '../../views/Dashboard/Dashboard'
import Calendario from '../../views/Calendario/Calendario'
import Planificacion from '../../views/Planificacion/Planificacion'
import Perfil from '../../views/Perfil/Perfil'

import AlumnoAsistencias from '../../views/Alumno/Asistencias/Asistencias'
import AlumnoFicha from '../../views/Alumno/Ficha/Ficha'
import AlumnoNotas from '../../views/Alumno/Notas/Notas'
import AlumnoClassfy from '../../views/Alumno/Classfy/Classfy'
import AlumnoGaleria from '../../views/Alumno/Galeria/Galeria'
import AlumnoCuadernillos from '../../views/Alumno/Cuadernillos/Cuadernillos'

import LeadFicha from '../../views/Lead/Ficha/Ficha'
import LeadContactos from '../../views/Lead/Contactos/Contactos'
import LeadClassfy from '../../views/Lead/Classfy/Classfy'

import FormacionFicha from "../../views/Formacion/Ficha/Ficha"
import FormacionAsistencias from "../../views/Formacion/Asistencias/Asistencias"
import FormacionClassfy from "../../views/Formacion/Classfy/Classfy"
import FormacionGaleria from "../../views/Formacion/Galeria/Galeria"
import FormacionCuadernillos from "../../views/Formacion/Cuadernillos/Cuadernillos"

import Horario from '../../views/Horario/Horario'

const Layout = () => {
  const [help, setHelp] = useState(false)

  return (
    <>
      <Header onHelpClick={() => setHelp(true)} />
      <SideMenu />
      <Content>
        <Routes>
          <Route path='/' element={<Dashboard />} />
          <Route path='/calendario' element={<Calendario />} />

          <Route path='/perfil' element={<Perfil />} />

          <Route path='/planificacion' element={<Planificacion />} />

          <Route path='/horario' element={<Horario />} />

          <Route path='/alumnos/:id/ficha' element={<AlumnoFicha />} />
          <Route path='/alumnos/:id/asistencias' element={<AlumnoAsistencias />} />
          <Route path='/alumnos/:id/notas' element={<AlumnoNotas />} />
          <Route path='/alumnos/:id/classfy' element={<AlumnoClassfy />} />
          <Route path='/alumnos/:id/galeria' element={<AlumnoGaleria />}/>
          <Route path='/alumnos/:id/cuadernillos' element={<AlumnoCuadernillos />}/>

          <Route path='/leads/:id/ficha' element={<LeadFicha />} />
          <Route path='/leads/:id/contactos' element={<LeadContactos />} />
          <Route path='/leads/:id/classfy' element={<LeadClassfy />} />

          <Route path='/formaciones/:id/ficha' element={<FormacionFicha />}/>
          <Route path='/formaciones/:id/asistencias' element={<FormacionAsistencias/>}/>
          <Route path='/formaciones/:id/classfy' element={<FormacionClassfy/>}/>
          <Route path='/formaciones/:id/galeria' element={<FormacionGaleria/>}/>
          <Route path='/formaciones/:id/cuadernillos' element={<FormacionCuadernillos/>}/>

          <Route path='/helpdesk' element={<Helpdesk />} />

        </Routes>
      </Content>

      <Help open={help} onClose={() => setHelp(false)} />
    </>
  )
}

export default Layout
